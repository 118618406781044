import axios from 'axios'
import { API_URL } from './utils'

/**
 *
 * @param {string} checkinUid
 * @param {Array} items
 * @returns { Promise }
 */
export async function calculateTips(
  placeUid,
  objectUid,
  items
) {
  try {
    const {
      data: { error_code: errorCode, description, payload: { total, tips, mayber_fee, service_fee } },
    } = await axios.post(API_URL(`/orders/calculateTipsNew`), {
      place_uid: placeUid,
      object_uid: objectUid,
      items,
    })

    if (errorCode) {
      return { error: { code: errorCode, description } }
    }
    return { total, tips, mayber_fee, service_fee }
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: { status: 401 } }
    }
    throw Error(error)
  }
}

/**
 *
 * @param {string} placeUid
 * @param {Array} itemUids
 * @param {number} tips
 * @returns { Promise }
 */
export async function ordersPayNew(
  placeUid,
  itemUids,
  payment_type,
  user_uid,
  tips = 0,
  email,
) {
  try {
    const {
      data: { error_code: errorCode, description, payload },
    } = await axios.post(API_URL(`/orders/payNew`), {
      place_uid: placeUid,
      item_uids: itemUids,
      payment_type,
      user_uid,
      tips,
      email,
    })

    if (errorCode) {
      return { error: { code: errorCode, description } }
    }
    return payload
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: { status: 401 } }
    }
    throw Error(error)
  }
}

/**
 *
 * @param {string} place_uid
 * @param {string} object_uid
 * @returns { Promise }
 */
export async function getItemsByTable(
  place_uid,
  object_uid,
) {
  try {
    const {
      data: { error_code: errorCode, description, payload },
    } = await axios.post(API_URL(`/orders/getItemsByTable`), {
      place_uid,
      object_uid,
    })

    if (errorCode) {
      return { error: { code: errorCode, description } }
    }
    return payload
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: { status: 401 } }
    }
    throw Error(error)
  }
}
